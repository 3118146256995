<template>
  <div>
    <div
      v-if="dataFetched"
      :class="[
        localDisplayLang === 'ara'
          ? 'mainContainerContinueWatchAra'
          : 'mainContainerContinueWatch',
      ]"
      :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
    >
      <div
        class="continueWatchCardContainer"
        v-for="(item, index) in continueData"
        :key="index"
        @click="playContent(item, index)"
      >
        <div class="continueWatchCard">
          <img
            :src="item.poster.landscape"
            class="continueWatchPoster"
            alt=""
          />
          <img
            src="@/assets/icons/Ic_remove_mylist.svg"
            class="removeFromWatch"
            @click.stop="removeFromWatchlist(item)"
            alt
          />
          <img class="play-img" src="@/assets/icons/Ic_Play1.svg" alt="" />
          <!--Content details-->
          <!-- <span class="continueWatchTitle">{{
            item.seriesname ? item.seriesname : item.title
          }}</span> -->
          <!-- Season and episode info -->
          <!-- <div
            class="seasonAndEpisodeContainer"
            v-if="item.category === 'TVSHOW' && item.title"
          >

            <p class="season">{{ `S${item.seasonnum} : ${item.title}` }}</p>
          </div> -->
          <div
            v-if="dataFetched"
            class="grayLine"
            :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
          ></div>
          <div
            class="progressPercent"
            :style="{ width: calculatePercentage(item) + '%' }"
            :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'"
          ></div>
        </div>
      </div>
    </div>

    <div v-if="!dataFetched">
      <detailPageLoader></detailPageLoader>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import firebase from "firebase/app";
import "firebase/database";
import Utility from "@/mixins/Utility.js";
import FirebaseActions from "@/mixins/FirebaseActions";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      movieObjs: [],
      episodeObjs: [],
      continueWatchFirebaseData: null,
      newEpisodeObj: null,
      segEpisodeArr: null,
      continueWatchContent: [],
      continueWatchDataTemp: null,
      localDisplayLang: null,
      page: 1,
      pageMovie: 1,
      seriesPage: 1,
      moviesPage: 1,
      isMpegRequired: false,
      finalData: [],
      pageSize: 15,
      continueData: null,
      finalMovieData: [],
      dataFetched: false,
      episodeList: [],
      totalEpisodeCount: 0,
      isDirectPlay: true,
      availabilities: [],
      filteredAvailabilities: [],
    };
  },

  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    // this.continueWatchFirebaseData = this.$route.params.continueWatchData;
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }
    this.scrollTop();
    this.checkEpisodeContinueWatch();
    // this.filterContents();
  },
  components: {
    detailPageLoader: () =>
      import(
        /* webpackChunkName: "detailPageLoader" */ "@/components/DetailPage/detailPageLoader.vue"
      ),
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
        // this.getAvailability(this.content);
      }
    },
    availabilityList(val) {
      if (val) {
        this.availabilities = val;
        // this.getAvailability(this.content);
      }
    },
  },
  methods: {
    ...mapActions(["listContent"]),
    checkEpisodeContinueWatch() {
      this.continueWatchContent = [];
      this.continueWatchData = [];
      this.detailPlaylist = [];
      this.finalPlaylist = [];
      this.completeEpisodeListing = [];
      this.continueData = [];
      let path = "",
        response = null,
        episodeObjs = null,
        newEpisodeObj = null,
        segEpisodeArr = null,
        movieObjs = null;

      if (this.subscriberid || this.profileid) {
        path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;

        firebase
          .database()
          .ref(path)
          .once("value")
          .then((snapshot) => {
            response = this.collectionsToArray(snapshot.val());
            //this.totalFirebaseResponse = response

            // Filter the movies for continue watch

            movieObjs = response.filter((element) => {
              return (
                element.category === "MOVIE" &&
                element.status === "INPROGRESS" &&
                element.watchedduration
              );
            });

            console.log("ONLY MOVIE ARRAY ->", movieObjs);

            // Filter only the series with episodes key
            episodeObjs = response.filter((element) => {
              return element.episodes;
            });

            // Convert the collection to an array
            newEpisodeObj = [...this.collectionsToArray(episodeObjs)];

            // Create an array of only the episodes of the particular series
            newEpisodeObj.forEach((element) => {
              segEpisodeArr = this.collectionsToArray(element.episodes);
              //assign series title to each episode
              segEpisodeArr.forEach((el) => {
                el.title = element.title;
              });

              // Sort episodes based on timestamp
              segEpisodeArr = segEpisodeArr.sort((a, b) => {
                return b.updatedat - a.updatedat;
              });

              // Pick the first index after sorting as it will be the latest episode watched
              this.continueWatchContent.push(segEpisodeArr[0]);
            });

            this.continueWatchDataTemp = [
              ...this.continueWatchContent,
              ...movieObjs,
            ];

            this.continueWatchDataTemp = this.continueWatchDataTemp.sort(
              (a, b) => {
                return b.updatedat - a.updatedat;
              }
            );
            console.log(
              "CHECK EPISODE CONTINUE WATCH ======",
              this.continueWatchDataTemp
            );
           
            /// this.continueData = this.continueWatchDataTemp
            //this.totalFirebaseResponse = this.continueWatchDataTemp
            // this.setContinueWatchData(this.continueWatchDataTemp)
            this.checkLanguage();

            // this.continueWatchData = this.continueWatchData.filter((el) => {
            //   return el.status === "INPROGRESS";
            // });

            // this.continueData = this.continueWatchData.sort((a, b) => {
            //   return b.updatedat - a.updatedat;
            // });

            // this.checkLanguage();

            // console.log("THE FIREBASE CONTINUE WATCH DATA ->", this.continueWatchData);

            // if (this.continueWatchData.length === 0) {
            //   this.showContinuePlaceHolder = false;
            // } else {
            //   this.showContinuePlaceHolder = false;
            //   this.constructSlider();
            // }
          });
      }
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    calculatePercentage(item) {
      // console.log('PERCENTAGE IS ',item)
      return (item.watchedduration / item.duration) * 100;
    },
    filterContents() {
      // console.log(
      //   "continue watch firebase data from store",
      //   this.getContinueWatchData
      // );
      // this.continueWatchDataTemp = this.getContinueWatchData;
      //this.checkLanguage();
    },
    removeFromWatchlist(item) {
      console.log("remove from watch list continue watch see all====", item);
      eventBus.$emit("removeFromContinueWatch", item);
      setTimeout(() => {
        this.checkEpisodeContinueWatch();
      }, 2000);
      this.dataFetched = false;
    },
    checkLanguage() {
      console.log("CHECK LANGUAGE FUNC CALLED");

      const seriesObjIds = [];
      const moviesObjIds = [];

      console.log("THE LOCAL LANG", this.localDisplayLang);
      this.continueWatchDataTemp.forEach((element) => {
        console.log("THE ELEMENTS LANG", element.displaylanguage);

        if (element.category == "TVSHOW") {
          seriesObjIds.push(`"${element.objectid}"`);
        } else if (element.category == "MOVIE") {
          moviesObjIds.push(`"${element.objectid}"`);
        }
      });
      this.callPromises(seriesObjIds, moviesObjIds);
    },
    playContent(content) {
      if (this.isDirectPlay && this.subscriberid) {
        this.showPlayer();

        if (content.category == "MOVIE") {
          let episodeIndex = 0;
          this.episodeList = [content];
          this.totalEpisodeCount = 1;

          this.loadChosenIndexContent(
            episodeIndex,
            this.episodeList,
            this.totalEpisodeCount,
            true
          );
        } else {
          let returnCode = this.fetchEpisodeRecursively(
            this.fetchEpisodes,
            content,
            this.checkEpisodeInList,
            false
          );

          returnCode
            .then(() => {
              let episodeIndex = this.getEpisodeIndexInList(
                content.objectid,
                this.episodeList
              );

              this.loadChosenIndexContent(
                episodeIndex,
                this.episodeList,
                this.totalEpisodeCount,
                true
              );
            })
            .catch((error) => {
              console.log("Error encountered : ", error);
            });
        }
      } else if (!this.subscriberid) {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    showPlayer() {
      this.playerInstance.blowUpPlayer();
      this.playerInstance.registerEvent(
        "currentcontentended",
        this.loadNextContentData
      );
      this.playerInstance.registerEvent(
        "loadchosencontent",
        this.loadChosenContentData
      );
      this.playerInstance.registerEvent(
        "loadmorecontent",
        this.loadMoreContentData
      );

      this.playbackInitiated = true;
      eventBus.$emit("player-visibility", true);
    },
    callPromises(seriesObjIds, moviesObjIds) {
      let seriesPromise;
      let moviesPromise;
      //SERIES PROMISE
      if (seriesObjIds && seriesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + seriesObjIds + "]",
          displaylanguage: this.localDisplayLang,
          page: this.seriesPage,
          pageSize: this.pageSize,
        };
        seriesPromise = new Promise((resolve, reject) => {
          this.fetchSeriesDataRecursively(payload).then((data) => {
            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.forEach((data, dIndex) => {
                if (data.objectid === content.objectid) {
                  this.continueWatchDataTemp[cIndex] = {
                    ...content,
                    title: data.title,
                    seriesname: data.seriesname,
                    contentdetails: data.contentdetails,
                  };
                }
              });
            });

            setTimeout(()=>{
              resolve(this.continueWatchDataTemp);
            },2000)
          });
        });
      }

      //MOVIE PROMISE
      if (moviesObjIds && moviesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + moviesObjIds + "]",
          displaylanguage: this.localDisplayLang,
          objecttype: "MOVIE",
          page: this.moviesPage,
          pageSize: this.pageSize,
        };
        moviesPromise = new Promise((resolve, reject) => {
          this.fetchMoviesDataRecursively(payload).then((data) => {
            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.forEach((data, dIndex) => {
                if (data.objectid === content.objectid) {
                  this.continueWatchDataTemp[cIndex] = {
                    ...content,
                    title: data.title,
                    contentdetails: data.contentdetails,
                  };
                }
              });
            });

           setTimeout(()=>{
              resolve(this.continueWatchDataTemp);
           },2000)
          });
        });
      }

      //RESOLVE BOTH PROMISES
      Promise.all([seriesPromise, moviesPromise]).then((finalData) => {
        this.continueWatchData = finalData[1];
        if (this.continueWatchData && this.continueWatchData.length > 0) { 
         this.continueData = this.continueWatchData.sort((a, b) => {
            return b.updatedat - a.updatedat;
          });
        }
        this.continueData = this.continueData.filter((item) => {
          return item.status && item.watchedduration;
        });
        this.dataFetched = true;
      });
    },
    fetchSeriesDataRecursively(payload) {
      return new Promise((resolve, reject) => {
        this.listContent(payload).then((data) => {
          this.finalData = [...this.finalData, ...data.data.data];
          if (this.finalData.length < data.data.totalcount) {
            let newPayload = {
              contentlist: payload.contentlist,
              displaylanguage: payload.displaylanguage,
              page: payload.page + 1,
              pagesize: this.pageSize,
            };

            this.fetchSeriesDataRecursively(newPayload);
          }
          setTimeout(() => {
            resolve(this.finalData);
          }, 2000);
        });
      });
    },
    fetchMoviesDataRecursively(payload) {
      return new Promise((resolve, reject) => {
        this.listContent(payload).then((data) => {
          this.finalMovieData = [...this.finalMovieData, ...data.data.data];
          if (this.finalMovieData.length < data.data.totalcount) {
            let newPayload = {
              contentlist: payload.contentlist,
              displaylanguage: payload.displaylanguage,
              page: payload.page + 1,
              pagesize: this.pageSize,
            };

            this.fetchMoviesDataRecursively(newPayload);
          }
          setTimeout(() => {
            resolve(this.finalMovieData);
          }, 2000);
          //resolve(this.finalMovieData);
        });
      });
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "profileid",
      "getRtl",
      "getContinueWatchData",
    ]),
  },
  mixins: [Utility, PlaybackUtilities],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./continueWatchseeAll.scss"
</style>
